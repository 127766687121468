import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA6zNTxFKhAUojSrSc0ekQG5opoo5aKqF8",
  authDomain: "ominiboxtoken.firebaseapp.com",
  projectId: "ominiboxtoken",
  storageBucket: "ominiboxtoken.appspot.com", // Corrected here
  messagingSenderId: "371524778910",
  appId: "1:371524778910:web:ef93c308ac5ef80c13b48f",
  measurementId: "G-XSPRZFVY69",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export {db};
