import React, { useEffect, useState } from "react";
import "./App.css";
import logo from "./logo.svg";

import { doc, setDoc, getDoc } from "firebase/firestore";
import {
  FaHome,
  FaUserCircle,
  FaCog,
  FaArrowLeft,
  FaBatteryFull,
} from "react-icons/fa";
import Home from "./pages/Home";
import SettingsComponent from "./pages/Home";
import ProfileComponent from "./pages/Home";
import { db } from "./Firebase";
import BottomNavbar from "./componentsd/BottomNavbar";
import { HashRouter, Route, Routes } from "react-router-dom";

function App() {
  const [savedUserId, setSavedUserId] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isPremium, setIsPremium] = useState(false);
  const [points, setPoints] = useState(0);
  const [activeTab, setActiveTab] = useState("Home");

  useEffect(() => {
    const tg = window.Telegram?.WebApp;

    if (!tg) {
      setError(
        "Telegram Web App is not initialized. Please open this app in Telegram."
      );
      setIsLoading(false);
      return;
    }

    const user = tg.initDataUnsafe?.user;

    if (!user) {
      setError(
        "No Telegram user data found. Please ensure you launch this app through Telegram."
      );
      setIsLoading(false);
      return;
    }

    // Update local state with user details
    setUserDetails({
      first_name: user.first_name || "No first name",
      last_name: user.last_name || "",
      username: user.username || "No username",
      is_premium: user.is_premium || false,
    });

    const saveUserId = async () => {
      try {
        const userId = user.id.toString(); // Ensure user ID is a string
        const userDocRef = doc(db, "users", userId);

        // Save or update the user document in Firestore
        await setDoc(
          userDocRef,
          {
            id: userId,
            joinedDate: new Date(), // Save the current date as the joined date
          },
          { merge: true } // Ensure existing fields aren't overwritten unnecessarily
        );

        // Update saved user ID
        setSavedUserId(userId);

        // Check premium status if needed
        checkPremiumStatus(user);
      } catch (error) {
        console.error("Error saving user ID:", error);
        setError("Failed to save user ID. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    };

    saveUserId();
  }, []);

  const checkPremiumStatus = (user) => {
    if (user.is_premium) {
      setIsPremium(true);
    }
  };

  const calculatePoints = (joinedDate) => {
    const accountAgeInYears =
      (new Date() - new Date(joinedDate.toDate())) /
      (1000 * 60 * 60 * 24 * 365);
    let earnedPoints = 0;

    if (accountAgeInYears < 1) {
      earnedPoints = 100;
    } else if (accountAgeInYears < 2) {
      earnedPoints = 200;
    } else {
      earnedPoints = 300;
    }

    setPoints(earnedPoints);
  };

  if (isLoading) {
    return (
      <div
        className="flex items-center justify-center h-screen bg-cover bg-center"
        style={{ backgroundImage: "url('/path-to-background-image.jpg')" }}
      >
        <div className="text-center">
          <div className="w-48 h-48 border-8 border-dashed rounded-full animate-spin border-gray-400"></div>
          <p className="mt-5 text-white text-xl">Loading, please wait...</p>
          <div className="w-3/4 mx-auto mt-4 h-1 bg-gray-200">
            <div
              className="h-full bg-green-500 animate-pulse"
              style={{ width: "75%" }}
            ></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <HashRouter className=" w-full h-lvh text-white">
      <div className="p-4">
        <img src={logo} className="mx-auto w-16 h-16" alt="logo" />
        {error ? (
          <p className="text-red-500 text-lg">{error}</p>
        ) : (
          <>
        
            <Routes>
              <Route
                path="/"
                  element={<Home telegramId={savedUserId} userDetails={userDetails} isPremium={isPremium} points={ points} />}
              />
            </Routes>
            <BottomNavbar />
          </>
        )}
      </div>
    </HashRouter>
  );
}

export default App;
