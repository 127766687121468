import React, { useState, useEffect } from "react";
import {
  doc,
  getDoc,
  updateDoc,
  setDoc,
  Timestamp,
  increment,
} from "firebase/firestore";
import { FaRocket, FaUsers } from "react-icons/fa";
import { db } from "../Firebase";

const Home = ({ telegramId, userDetails, isPremium, points }) => {
  const [minedTokens, setMinedTokens] = useState(0);
  const [timeElapsed, setTimeElapsed] = useState(0);
  const [referCount, setReferCount] = useState(0);
  const [isFarming, setIsFarming] = useState(false);
  const [endTime, setEndTime] = useState(null);
  const [hasNFTBoost, setHasNFTBoost] = useState(false);
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const maxTimeInSeconds = 43200; // 12 hours in seconds

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userRef = doc(db, "users", telegramId);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          const data = userDoc.data();
          setUserData(data);
          setReferCount(data.referralCount || 0);

          if (data.startTime && data.endTime && data.isFarming) {
            const currentTime = Date.now();
            const startTimestamp = data.startTime.seconds * 1000;
            const calculatedEndTime = data.endTime.seconds * 1000;
            const elapsed = Math.floor((currentTime - startTimestamp) / 1000);

            setIsFarming(true);
            setEndTime(new Date(calculatedEndTime));
            setTimeElapsed(Math.min(elapsed, maxTimeInSeconds));
          }
        } else {
          // Initialize user data if it doesn't exist
          await setDoc(
            userRef,
            { points: points, referralCount: 0 },
            { merge: true }
          );
          setUserData({ points: points, referralCount: 0 });
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    const checkNFTBoost = async () => {
      // Simulated check for NFT boost (replace with real logic)
      const hasNFT = await checkIfUserHasNFT();
      setHasNFTBoost(hasNFT);
    };

    fetchUserData();
    checkNFTBoost();
  }, [telegramId]);

  const checkIfUserHasNFT = async () => {
    // Replace with actual logic to check if user owns an NFT
    return true;
  };

  useEffect(() => {
    let timer;

    if (isFarming && timeElapsed < maxTimeInSeconds) {
      timer = setInterval(() => {
        setTimeElapsed((prev) => {
          const newElapsed = prev + 1;
          const newMinedTokens = (newElapsed * calculateMiningRate()) / 3600;
          setMinedTokens(newMinedTokens);
          return newElapsed;
        });
      }, 1000);
    } else if (timeElapsed >= maxTimeInSeconds) {
      setIsFarming(false);
    }

    return () => clearInterval(timer);
  }, [isFarming, timeElapsed]);

  const calculateMiningRate = () => {
    const baseRate = 0.05; // Base rate per hour
    const boost = 0.005 * referCount; // Boost of 0.005 per referral
    const nftBoost = hasNFTBoost ? 0.01 : 0; // NFT boost of 0.01 if user has NFT
    return baseRate + boost + nftBoost;
  };

  const startFarming = async () => {
    try {
      const userRef = doc(db, "users", telegramId);
      const currentTime = new Date();
      const calculatedEndTime = new Date(
        currentTime.getTime() + maxTimeInSeconds * 1000
      );

      setIsFarming(true);
      setMinedTokens(0);
      setTimeElapsed(0);
      setEndTime(calculatedEndTime);

      await setDoc(
        userRef,
        {
          startTime: Timestamp.fromDate(currentTime),
          endTime: Timestamp.fromDate(calculatedEndTime),
          isFarming: true,
        },
        { merge: true }
      );
    } catch (error) {
      console.error("Error starting farming:", error);
    }
  };

  const claimTokens = async () => {
    try {
      const userRef = doc(db, "users", telegramId);
      const totalMinedTokens = (timeElapsed * calculateMiningRate()) / 3600;
      const roundedTokens = parseFloat(totalMinedTokens.toFixed(3));
      await updateDoc(userRef, {
        points: increment(roundedTokens),
        isFarming: false,
      });

      setMinedTokens(0);
      setTimeElapsed(0);
      setIsFarming(false);
    } catch (error) {
      console.error("Error claiming tokens:", error);
    }
  };

  const handleClick = () => {
    if (timeElapsed >= maxTimeInSeconds) {
      claimTokens();
    } else if (!isFarming) {
      startFarming();
    }
  };

  const progressPercentage = (timeElapsed / maxTimeInSeconds) * 100;

  // if (isLoading || !userData) {
  //   return (
  //     <div className="text-center text-lg text-lemongreen">Loading...</div>
  //   );
  // }

  return (
    <div className="bg-transparent h-lvh flex flex-col items-center justify-center p-6">
      {userDetails ? (
        <div className="absolute top-0 left-0 w-full p-4 bg-transparent shadow-lg">
          <div className="flex flex-wrap gap-4">
            {/* First Tab - Full Width */}
            <div className="w-full p-4 bg-white/30 backdrop-blur-sm rounded-lg shadow-md">
              <p className="text-lg font-semibold text-gray-800">
                Hello, {userDetails.first_name} {userDetails.last_name} (@
                {userDetails.username}).
              </p>
            </div>

            {/* Second and Third Tabs - Side by Side */}
            <div className="flex w-full gap-4">
              <div className="flex-1 p-4 bg-white/30 backdrop-blur-sm rounded-lg shadow-md">
                <p className="text-sm text-gray-600">
                  Status:{" "}
                  <span className="font-medium">
                    {isPremium ? "True OG" : "OG"}
                  </span>
                </p>
              </div>
              <div className="flex-1 p-4 bg-white/30 backdrop-blur-sm rounded-lg shadow-md">
                <p className="text-sm text-gray-600">{points} BOX points</p>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div className="p-6 w-full">
        <img
          src={require("../assets/glowing-book-wooden-base.png")}
          className="w-[100%] h-auto max-w-4xl mx-auto block "
        />

        <button
          onClick={handleClick}
          className={`w-[70%] absolute left-[15%] mx-auto bottom-[75px] py-3 rounded-lg font-bold text-black shadow-lg focus:outline-none transition-all border ${
            timeElapsed >= maxTimeInSeconds
              ? "bg-gradient-to-r from-green-400 to-lemongreen"
              : isFarming
              ? "bg-transparent border-white"
              : "bg-gradient-to-r from-green-500 to-green-700"
          }`}
          style={{
            background: isFarming
              ? `linear-gradient(to right, teal ${progressPercentage}%, transparent ${progressPercentage}%)`
              : undefined,
          }}
        >
          {isFarming
            ? `${minedTokens.toFixed(6)} BOX`
            : timeElapsed >= maxTimeInSeconds
            ? `Claim - ${((timeElapsed * calculateMiningRate()) / 3600).toFixed(
                6
              )} BOX`
            : "Start Farming"}
        </button>
      </div>
    </div>
  );
};

export default Home;
