import React from "react";
import { AiOutlineHome, AiOutlineWallet } from "react-icons/ai";
import { FaTasks } from "react-icons/fa";
import { RiDropLine } from "react-icons/ri";
import { NavLink } from "react-router-dom"; // Assuming you're using React Router for navigation

const BottomNavbar = () => {
  const navLinkClass = `flex flex-col p-2  items-center text-sm transition-all`;

  return (
    <nav className="fixed h-[65px] bottom-0 left-0 w-full bg-white/30 backdrop-blur-md shadow-lg">
      <div className="flex justify-around items-center py-1">
        <NavLink
          to="/"
          className={({ isActive }) =>
            `${navLinkClass} ${
              isActive
                ? "text-black bg-lime-400 rounded-lg font-semibold"
                : "text-gray-600"
            } hover:text-black`
          }
        >
          <AiOutlineHome className="w-6 h-6" />
          <span>Farm</span>
        </NavLink>

        <NavLink
          to="/tasks"
          className={({ isActive }) =>
            `${navLinkClass} ${
              isActive
                ? "text-black bg-lime-400 rounded-lg font-semibold"
                : "text-gray-600"
            } hover:text-black`
          }
        >
          <FaTasks className="w-6 h-6" />
          <span>Tasks</span>
        </NavLink>

        <NavLink
          to="/airdrop"
          className={({ isActive }) =>
            `${navLinkClass} ${
              isActive
                ? "text-black bg-lime-400 rounded-lg font-semibold"
                : "text-gray-600"
            } hover:text-black`
          }
        >
          <RiDropLine className="w-6 h-6" />
          <span>Airdrop</span>
        </NavLink>

        <NavLink
          to="/wallet"
          className={({ isActive }) =>
            `${navLinkClass} ${
              isActive
                ? "text-black bg-lime-400 rounded-lg font-semibold"
                : "text-gray-600"
            } hover:text-black`
          }
        >
          <AiOutlineWallet className="w-6 h-6" />
          <span>Wallet</span>
        </NavLink>
      </div>
    </nav>
  );
};

export default BottomNavbar;
